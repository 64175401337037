.img_placeholder {
  display: none !important;
  height: 0;
  width: 0;
  overflow: hidden;

  &::before,
  &::after {
    display: none !important;
  }
}

.twitter_container {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  // border: 1px solid red;

  .anchor_cover {
    position: relative;
    width: 100%;
    display: block;
    padding: 4px 10px;
    box-sizing: border-box;
    border: 1px solid rgba($color: #e0e0e0, $alpha: 0.6);
    border-radius: 4px;
    // border: 1px solid red;
  }

  &_flex {
    padding: 4px 0;
    display: flex;
    justify-content: center;
  }

  &_default {
    display: block;
    text-align: left;
  }
}
