@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/assets.scss';

.docs {
  position: absolute;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  .header {
    position: relative;
    height: 55px;
    width: 90%;
    margin-top: 25px;
    margin-left: 5%;
    overflow: hidden;

    &_action {
      position: relative;
      height: 100%;
      padding: 0;
      display: inline-block;
      vertical-align: middle;

      .login {
        position: relative;
        display: inline-block;
        text-align: center;

        & > p {
          font-weight: 600;

          & > a:nth-child(1),
          & > a:nth-child(2) {
            margin-right: 3px;
            font-weight: 700;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .user_home {
        position: relative;
        display: inline-block;
        text-align: center;
        height: 100%;

        &_margin {
          margin-left: calc(100% - 180px);
        }

        & > div:nth-child(1) {
          height: 40px;
          width: 40px;
          position: relative;
          display: inline-block;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;

          & > h5,
          & > h4 {
            position: relative;
            line-height: 40px;
            text-align: center;
            height: 100%;
            width: 100%;
            background: $app_black_shade1;
            color: $app_white_absolute;
            font-weight: 700;
            font-size: 14.5px;
          }

          .cover {
            cursor: pointer;

            a {
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              text-decoration: none;
            }
          }
        }
      }
    }

    & > div:nth-child(1) {
      position: relative;
      width: 100%;
      display: inline-block;
    }

    .logo_wrap {
      position: relative;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-left: 10px;
      margin-top: 5px;
    }

    &_title {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-left: 10px;

      & > h4 {
        font-weight: 700;
        position: relative;
        display: inline-block;
      }
    }
  }

  .actual {
    position: relative;
    margin-left: 5%;
    width: 90%;
    margin-top: 60px;
    display: block;

    & > div:nth-child(1) {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;
    }

    .nav,
    .content {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: top;
    }

    .nav {
      width: 280px;
      margin-left: 20px;

      & > div:nth-child(2) {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $app_white_shade3;
        padding: 5px 10px;
        border-radius: 4px;
        display: none;

        .nav_mobile_arr_expand {
          transform: rotate(-180deg);
        }

        .nav_mobile_expand {
          height: unset;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .nav_mobile_noexpand {
          height: 0;
          margin: 0;
          overflow: hidden;
        }
      }

      & > div:nth-child(1) {
        position: relative;
        display: inline-block;
        padding: 15px 12px;
        padding-top: 7px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid $app_white_shade3;

        & > ul {
          width: 100%;
          list-style: none;
          list-style-type: none;

          .nav_inactive {
            & > h4 {
              color: $app_gray_shade2;
            }
          }

          .nav_active {
            & > h4 {
              color: $app_black_shade1;
            }
          }

          .cover {
            cursor: pointer;

            a {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
            }
          }

          & > li {
            position: relative;
            width: 100%;
            display: inline-block;
            margin-top: 5px;

            .dot {
              height: 15px;
              width: 15px;
              text-align: center;
              line-height: 15px;
              font-size: 16px;
            }

            .dot,
            & > h4 {
              position: relative;
              display: inline-block;
              vertical-align: middle;
              box-sizing: border-box;
            }

            & > h4 {
              width: calc(100% - 25px);
              margin-left: 10px;
              line-height: 20px;
              font-weight: 700;
              text-align: left;
            }
          }

          & > li:not(:nth-child(1)) {
            margin-top: 15px;
          }
        }
      }
    }

    .content {
      width: calc(100% - 330px);
      margin-left: 30px;
    }
  }
}

@media screen and (min-width: 1050px) {
  .docs {
    .actual,
    .header {
      width: 1050px;
      margin-left: calc((100% - 1050px) / 2);
    }
  }
}

@media screen and (max-width: 900px) {
  .docs {
    .actual {
      .content,
      .nav {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }

      .nav {
        & > div:nth-child(1) {
          display: none;
          width: 0;
          height: 0;
          overflow: hidden;
        }

        & > div:nth-child(2) {
          display: inline-block;
          margin-bottom: 10px;

          & > div:nth-child(1) {
            position: relative;
            display: inline-block;
            text-align: center;

            button {
              padding: 0 30px;
              display: inline-block;
              text-align: center;
              box-sizing: border-box;

              & > h4,
              & > div {
                position: relative;
                display: inline-block;
              }

              & > h4 {
                font-weight: 800;
                text-align: center;
                line-height: 20px;
              }

              & > div:nth-child(2) {
                margin-left: 5px;
                height: 16px;
                width: 16px;
                background: url($arrow_down_black_2);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                transition: transform 0.2s ease;
                vertical-align: middle;
              }
            }
          }

          & > div:nth-child(2) {
            position: relative;
            width: 100%;
            transition: all 0.1s ease-in-out;

            & > ul {
              position: relative;
              width: 100%;
              list-style: none;
              list-style-type: none;

              .cover {
                cursor: pointer;

                a {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0;
                }
              }

              & > li {
                position: relative;
                width: 100%;
                display: inline-block;
                margin-top: 5px;

                .dot {
                  height: 15px;
                  width: 15px;
                  text-align: center;
                  line-height: 15px;
                  font-size: 16px;
                }

                .dot,
                & > h4 {
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  box-sizing: border-box;
                }

                & > h4 {
                  width: calc(100% - 25px);
                  margin-left: 10px;
                  line-height: 20px;
                  font-weight: 700;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .content {
        margin-top: 50px;
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .docs {
    .header {
      margin-left: 2.5%;
      width: 95%;
    }
  }
}

@media screen and (max-width: 350px) {
  .docs {
    .header {
      margin-left: 2%;
      width: 96%;
    }
  }
}
