@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.forgot_password_wrap {
  position: relative;
  height: 100%;
  width: 100%;
  background: $app_white_absolute;
  box-sizing: border-box;

  .header {
    position: relative;
    height: 80px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    //border: 1px solid red;

    &_logo {
      margin-left: 25px;
      display: inline-block;
      //border: 1px solid red;
      vertical-align: middle;
    }

    .return {
      position: absolute;
      height: 90%;
      top: 0;
      right: 15px;
      padding: 0 10px;
      //border: 1px solid red;
      text-align: center;

      & > h4 {
        p {
          font-weight: 600;

          a {
            font-weight: 700;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          & > a:nth-child(1) {
            margin-right: 3px;
          }

          & > a:nth-child(2) {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .on_success {
    position: relative;
    width: 100%;
    margin-top: 70px;

    &_msg {
      position: relative;
      margin-top: 20px;
      min-width: 200px;
      max-width: 310px;
      overflow-wrap: break-word;

      & > h4 {
        font-weight: 700;
        font-size: 86%;
        text-align: center;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    margin-top: 50px;

    .title {
      position: relative;
      min-width: 200px;
      max-width: 550px;

      h2 {
        text-align: left;
        position: relative;
        font-weight: bold;
        font-weight: 800;
        line-height: 22px;
      }
    }

    .msg {
      position: relative;
      margin-top: 20px;
      min-width: 200px;
      max-width: 350px;
      overflow-wrap: break-word;

      & > p {
        text-align: center;
        position: relative;
        font-weight: 700;
        font-size: 80%;
        line-height: 22px;
        color: $app_gray_shade2;
      }
    }

    .email {
      position: relative;
      min-width: 200px;
      max-width: 550px;
      margin-top: 30px;

      &_input {
        position: relative;
        width: 300px;

        label {
          font-weight: 600 !important;
          font-family: 'Manrope' !important;
          font-size: 90% !important;
        }

        input[type='text'],
        input[type='password'] {
          width: 100%;
          font-size: 80%;
          font-weight: 500;
          font-family: 'Manrope' !important;
          height: 40px;
        }
      }
    }

    .spinner_wrap {
      position: relative;
      width: 250px;

      .raw {
        position: relative;

        &_spin {
          height: 30px;
          width: 30px;
        }
      }
    }

    .actions {
      position: relative;
      max-width: 550px;
      margin-top: 20px;
      margin-bottom: 40px;

      .confirm {
        position: relative;
        padding: 11px 10px;
        width: 280px;
      }
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .forgot_password_wrap {
    width: $max_width_fix;
    margin-left: calc((100% - #{$max_width_fix}) / 2);
  }
}

@media screen and (max-width: 610px) {
  .forgot_password_wrap {
    .header {
      .return {
        height: 70%;
        margin-bottom: 10px;
      }
    }
    .content {
      .title {
        h2 {
          text-align: center;
        }
      }

      .msg {
        max-width: unset;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .forgot_password_wrap {
    .header {
      &_logo {
        margin-left: 5px;
      }

      .return {
        right: 5px;
      }
    }

    .content {
      .email {
        &_input {
          width: 240px;
        }
      }

      .actions {
        .confirm {
          width: 240px;
        }
      }
    }
  }
}
