@import '../../theme/commons.scss';

.init_user_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .msg {
    position: relative;
    max-width: 295px;
    margin-top: 15px;

    & > h4 {
      line-height: 20px;
      font-weight: 600;
      text-align: center;
    }
  }
}
