@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.load_spinner {
  position: relative;
  height: 50px;
  width: 50px;

  .ring_parent {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      animation-delay: -0.4;
    }

    & > div:nth-child(2) {
      animation-delay: -0.3;
    }

    & > div:nth-child(3) {
      animation-delay: -0.12s;
    }
  }

  .ring {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 8px solid $app_dominant_purple;
    border-radius: 50%;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $app_dominant_purple transparent transparent transparent;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
