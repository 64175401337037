$max_width_fix: 1400px;
$high_front: 5;
$normal_front: 4;
$middle: 3;
$back: 2;
$max_width_fix_user_dashboard: 1680px;
$max_width_fix_task_view: 1820px;
$max_width_fix_task_create: 1550px;

@keyframes opacity-load {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.7;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.cover_link {
  a {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
  }
}

.cover {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  left: 0;
}

.pointer {
  cursor: pointer;
}

.hide_element {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
}

.flex_content {
  display: flex;
}

.flex_row_xy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_row_y {
  display: flex;
  align-items: center;
}

.flex_row_x {
  display: flex;
  justify-content: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_column_xy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_column_x {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_column_y {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
