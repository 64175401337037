@import '../../../theme/commons.scss';
@import '../../../theme/brand.scss';
@import '../../../theme/color.scss';

.docs_intro {
  position: relative;
  width: 100%;

  .label {
    width: 100%;
    text-align: left;

    & > h2 {
      position: relative;
      display: inline-block;
      max-width: 100%;
      font-weight: 800;
      font-size: 210%;
    }
  }

  .icon_one {
    position: relative;
    width: 100%;
    text-align: left;

    & > div:nth-child(1) {
      display: inline-block;
      position: relative;
      width: 90px;
      height: 160px;
      background: url($bear_black_border_one);
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
    }
  }

  .message {
    width: 100%;
    text-align: left;
    margin-top: 20px;

    & > p {
      position: relative;
      max-width: 90%;
      line-height: 28px;

      a {
        font-weight: 700;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .resources {
    position: relative;
    width: 100%;
    text-align: left;
    margin-top: 30px;

    & > h5 {
      font-weight: 600;
      display: inline-block;
      position: relative;
      max-width: 90%;

      & > p {
        font-weight: 600;
        position: relative;
        display: inline-block;
        color: $app_gray_shade2;

        a {
          font-weight: 800;
          text-decoration: none;
        }

        & > a:nth-child(2) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
