@import '../../theme/color.scss';
@import '../../theme/commons.scss';
@import './Annex.scss';

.app_logo {
  position: relative;
  padding: 5px 10px;
  box-sizing: border-box;

  .fav_icon {
    position: relative;
    height: 40px;
    width: 40px;
    margin-bottom: 10px;

    .logo {
      position: relative;
      height: 40px;
      width: 40px;
      background: url($logo_ic);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .word {
    line-height: 20px;
    font-size: 32px;
    font-weight: 700;
    color: #323333;
    margin-left: 7px;
    margin-bottom: 13px;
  }

  .beta_title {
    position: absolute;
    bottom: -2px;
    right: 0;
    padding: 3px 8px;
    border-radius: 3px;
    border: 1px solid $app_dominant_purple_bg;
    text-align: center;
    display: inline-block;

    & > h5 {
      color: $app_dominant_purple;
      font-weight: 700;
      font-size: 11.5px;
    }
  }
}

@media screen and (max-width: 600px) {
  .app_logo {
    padding: 5px 7px;

    .fav_icon {
      height: 32px;
      width: 32px;

      p {
        font-size: 22px;
      }
    }

    .word {
      font-size: 25px;
      margin-bottom: 0;
    }

    .fav_icon {
      margin: 0;
    }
  }
}
