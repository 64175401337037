@import '../../../theme/color.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/task.scss';

.docs_about {
  position: relative;
  width: 100%;

  .label {
    width: 100%;
    text-align: left;

    & > h2 {
      position: relative;
      display: inline-block;
      max-width: 100%;
      font-weight: 800;
      font-size: 210%;
    }
  }

  .msg_one,
  .msg_two {
    width: 100%;
    text-align: left;
    margin-top: 10px;

    & > p {
      line-height: 27px;

      span {
        font-weight: 800;
      }
    }
  }

  .msg_one {
    margin-top: 20px;
  }

  .mission {
    margin-top: 20px;
    position: relative;
    width: 100%;
    text-align: left;
    padding: 16px 25px;
    box-sizing: border-box;
    background-color: rgba($color: $app_white_light, $alpha: 1);
    border-radius: 7px;

    & > h3 {
      font-weight: 800;
      line-height: 26px;

      & > span {
        position: relative;
        display: inline-block;
        height: 32px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  .keys {
    position: relative;
    width: 100%;
    margin-top: 50px;

    & > div:nth-child(1) {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;

      & > ul {
        position: relative;
        list-style-type: none;
        list-style: none;
        display: inline-block;
        width: 100%;

        & > li {
          position: relative;
          display: inline-block;
          width: calc((100% - 30px) / 3);
          text-align: center;
          padding: 5px 6px;
          box-sizing: border-box;
          margin-top: 6px;
          margin-left: 5px;
        }

        .ic_fast,
        .ic_accessible,
        .ic_secure {
          position: relative;
          height: 30px;
          width: 30px;
          background: url($lightning_fast_black);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 70% 70%;
          margin-left: calc((100% - 30px) / 2);
          border-radius: 5px;
          border: 1px solid $app_white_shade3;
          box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
          -webkit-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
          -moz-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
        }

        .ic_secure {
          background: url($private_icon);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 70% 70%;
        }

        .ic_accessible {
          background: url($layer_page_black);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 70% 70%;
        }

        .key_desc {
          position: relative;
          width: 100%;
          display: inline-block;
          text-align: center;
          margin-top: 8px;

          & > h4 {
            font-weight: 700;
          }

          & > h5 {
            color: $app_gray_shade2;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .docs_about {
    .keys {
      margin-top: 30px;

      & > div:nth-child(1) {
        & > ul {
          & > li {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }

          & > li:not(:nth-child(1)) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
