body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  -webkit-overflow-x: hidden !important;
  background: #fff;
}

html {
  scrollbar-color: #e8e8e8 transparent;
}

* {
  padding: 0;
  margin: 0;
  color: #323333;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
}
