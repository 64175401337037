.low_opac_05 {
  opacity: 0.4;
}

pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  overflow-x: scroll;
  padding: 6px 12px;
  border-radius: 4px;
  box-sizing: border-box;
}
