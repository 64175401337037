@import '../../theme//commons.scss';
@import '../../theme/color.scss';

.logo_load {
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;

  .msg {
    position: relative;
    max-width: 290px;
    margin-top: 5px;

    & > h3 {
      font-weight: 700;
      text-align: center;
    }
  }
}
